import { defineStore } from 'pinia'
import ApiService from '@/service/api.service.js'
import { useUserStore } from '@/store/user'

export const useAccountStore = defineStore('account', {
  state: () => ({
    account: null,
    accounts: [],
    accountServices: [],
    totalAccounts: 0,
    accountFields: [
      {
        name: 'id',
        label: 'ID',
        type: 'Int',
        readOnly: true,
        rules: []
      },
      {
        name: 'sponsorEmployeeNumber',
        label: 'Sponsor',
        hint: 'Rakuten employee sponsoring the guest.',
        type: 'Employee',
        readOnly: false,
        editable: true,
        commonField: true,
        rules: [(v) => !!v || 'This is a required field']
      },
      {
        name: 'sponsorOrgCode',
        label: 'Sponsor Organization',
        hint: `Rakuten organization sponsoring the guest. The sponsor's main organization by default.`,
        type: 'Organization',
        readOnly: true,
        editable: true,
        commonField: true,
        rules: [(v) => !!v || 'This is a required field']
      },
      {
        name: 'username',
        label: 'Username',
        type: 'String',
        readOnly: true,
        editable: false,
        rules: []
      },
      {
        name: 'lastName',
        label: 'Last Name',
        type: 'String',
        required: true,
        readOnly: false,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 50) || 'Maximum 50 characters.',
          (v) =>
            !v ||
            !/[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/.test(
              v
            ) ||
            'Must be in Latin characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 50
      },
      {
        name: 'firstName',
        label: 'First Name',
        type: 'String',
        required: true,
        readOnly: false,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 50) || 'Maximum 50 characters.',
          (v) =>
            !v ||
            !/[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/.test(
              v
            ) ||
            'Must be in Latin characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 50
      },
      {
        name: 'lastNameLocalized',
        label: 'Last Name in local language',
        type: 'String',
        required: true,
        readOnly: false,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 50) || 'Maximum 50 characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 50
      },
      {
        name: 'firstNameLocalized',
        label: 'First Name in local language',
        type: 'String',
        required: true,
        readOnly: false,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 50) || 'Maximum 50 characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 50
      },
      {
        name: 'email',
        label: 'Email Address',
        hint: `Guest's work email address only (i.e. john.doe@microsoft.com). Personal email address is prohibited.`,
        type: 'Email',
        required: true,
        readOnly: false,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 100) || 'Maximum 100 characters.',
          (v) =>
            !v ||
            !/[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/.test(
              v
            ) ||
            'Must be in Latin characters.',
          (v) => 
            !v ||
              (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v
              ) &&
              v.match(/(?<=\@).*/)[0].length <= 254 &&
              v.match(/(?<=\@).*/)[0].length >= 2 &&
              v.match(/.+?(?=@)/)[0].length <= 64) ||
            'Email must be valid.'
        ],
        maxlength: 100
      },
      {
        name: 'companyName',
        label: 'Company Name in Latin characters',
        type: 'String',
        readOnly: true,
        editable: false,
        rules: []
      },
      {
        name: 'companyNameLocalized',
        label: 'Company Name in local language',
        type: 'String',
        readOnly: true,
        rules: []
      },
      {
        name: 'companyNameCommon',
        label: 'Company Common Name/Abbreviation',
        type: 'String',
        readOnly: true,
        editable: false,
        rules: []
      },
      {
        name: 'accountExpiry',
        label: 'Account Expiry',
        hint: 'Date account will expire',
        type: 'Date',
        readOnly: true,
        editable: false,
        rules: [(v) => !!v || 'This is a required field.']
      }
    ],
    accountHeaders: [
      {
        text: 'Username',
        value: 'username'
      },
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Company',
        value: 'companyName'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Expiry',
        value: 'accountExpiry'
      },
      {
        text: 'Sponsor',
        value: 'sponsor.longAccount'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      }
    ],
    accountHeadersWidget: [
      {
        text: 'Username',
        value: 'username'
      },
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Company',
        value: 'companyName'
      },
      {
        text: 'Sponsor',
        value: 'sponsor.longAccount'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],
    accountSnackbar: {
      text: null,
      color: null,
      value: false
    }
  }),
  getters: {
    getAccount(state) {
      return state.account
    },
    getAccounts(state) {
      return state.accounts
    },
    getAccountSnackbar(state) {
      return state.accountSnackbar
    }
  },
  actions: {
    resetAccounts() {
      this.accounts = []
    },
    async searchAccountsByEmailAsync(email) {
      return ApiService.customRequest({
        method: 'get',
        url: `/Search/Accounts/${email}`,
        headers: { 'Content-Type': 'application/json' }
      })
    },
    async searchAccountsAsync(q, type) {
      const userStore = useUserStore()

      let params = `employeeNumber=${userStore.user.employeeNumber}&query=${q}&type=${type}`

      return ApiService.customRequest({
        method: 'get',
        url: `/Accounts?${params}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.accounts = result.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async fetchAccountAsync(login, blnIncludeServices) {
      
      let params = `includeServices=${blnIncludeServices}`

      return ApiService.customRequest({
        method: 'get',
        url: `/Accounts/${login}?${params}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.account = result.data
          return {
            success: true,
            data: result.data
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.accountSnackbar = {
              text: `${error.message}. ${error.response.data}`,
              text: `${error.message}`,
              color: 'red darken-2',
              value: true
            }
          }
          return {
            success: false,
            error
          }
        })
    },
    async fetchAccountsAsync() {
      const userStore = useUserStore()
      let params = `employeeNumber=${userStore.user.employeeNumber}`

      ApiService.customRequest({
        method: 'get',
        url: `/Accounts?${params}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          this.accounts = res.data
          this.totalAccounts = res.data.length
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.requestSnackbar = {
              text: `Failed to update account list. Error: ${error.message}`,
              color: 'red darken-2',
              value: true
            }
          }
        })
    },
    async updateAccountAsync(id, data) {
      let fields = data.fields
      let obj = {}

      for (let key of Object.keys(fields)) {
        // if (key !== 'sponsorEmployeeNumber' && key !== 'sponsorOrgCode') {
        //   obj[key] = _.trim(fields[key])
        // }
        obj[key] = _.trim(fields[key])
      }

      return ApiService.customRequest({
        method: 'post',
        url: `/Accounts/${id}`,
        headers: { 'Content-Type': 'application/json' },
        data: obj
      })
        .then((result) => {
          this.accountSnackbar = {
            text: 'Account updated',
            color: 'green darken-2',
            value: true
          }
          return true
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.accountSnackbar = {
              text: `${error.message}`,
              color: 'red darken-2',
              value: true
            }
          }
          return false
        })
    },
    async resetFactorsAsync(username) {
      return ApiService.customRequest({
        method: 'post',
        url: `/Accounts/${username}/Lifecycle/Reset_Factors`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          this.accountSnackbar = {
            text: 'MFA factors reset',
            color: 'green darken-2',
            value: true
          }
          return true
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.accountSnackbar = {
              text: `${error.response.data}`,
              color: 'red darken-2',
              value: true
            }
          }
          return false
        })
    },
    async sendPasswordResetLinkAsync(username) {
      return ApiService.customRequest({
        method: 'post',
        url: `/Accounts/${username}/Lifecycle/Reset_Password`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          this.accountSnackbar = {
            text: 'Password reset link sent',
            color: 'green darken-2',
            value: true
          }
          return true
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.accountSnackbar = {
              text: `${error.response.data}`,
              color: 'red darken-2',
              value: true
            }
          }
          return false
        })
    },
    async sendActivationLinkAsync(username) {
      return ApiService.customRequest({
        method: 'post',
        url: `/Accounts/${username}/Lifecycle/Reactivate`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          this.accountSnackbar = {
            text: 'Activation link sent',
            color: 'green darken-2',
            value: true
          }
          return true
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.accountSnackbar = {
              text: `${error.response.data}`,
              color: 'red darken-2',
              value: true
            }
          }
          return false
        })
    },
    async deactivateAsync(username) {
      return ApiService.customRequest({
        method: 'post',
        url: `/Accounts/${username}/Lifecycle/Deactivate`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          this.accountSnackbar = {
            text: 'Guest account deactivated',
            color: 'green darken-2',
            value: true
          }
          return true
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.accountSnackbar = {
              text: `${error.response.data}`,
              color: 'red darken-2',
              value: true
            }
          }
          return false
        })
    },
    async fetchAccountServicesAsync(username) {
      return ApiService.customRequest({
        method: 'get',
        url: `/Accounts/${username}/Services`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.accountServices = result.data
          return {
            success: true,
            data: result.data
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.accountSnackbar = {
              text: `${error.message}. ${error.response.data}`,
              text: `${error.message}`,
              color: 'red darken-2',
              value: true
            }
          }
          return {
            success: false,
            error
          }
        })
    }
  }
})
