<template>
  <v-flex>
    <v-toolbar flat color="transparent">
      <v-toolbar-title
        class="secondary--text overline"
        v-text="tableTitle"
      ></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search Accounts"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>

    <v-data-table
      fixed-header
      :headers="headers"
      :items="filteredAccounts"
      class="elevation-0"
      :hide-default-footer="hideDefaultFooter"
      :footer-props="footerProps"
      :loading="isLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-snackbar
          v-model="accountSnackbar.value"
          :color="accountSnackbar.color"
          vertical
        >
          <span v-html="accountSnackbar.text"></span>
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="accountSnackbar.value = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <reset-factors
          :resetFactorsDialog.sync="resetFactorsDialog"
          :username="usernameForDialog"
        ></reset-factors>
        <send-password-reset-link
          :sendPasswordResetLinkDialog.sync="sendPasswordResetLinkDialog"
          :username="usernameForDialog"
        ></send-password-reset-link>
        <send-activation-link
          :sendActivationLinkDialog.sync="sendActivationLinkDialog"
          :username="usernameForDialog"
        ></send-activation-link>
        <deactivate-account
          :deactivateAccountDialog.sync="deactivateAccountDialog"
          :username="usernameForDialog"
        ></deactivate-account>
      </template>
      <template v-slot:[`item.username`]="{ item }">
        <router-link
          :to="{ name: 'show-account', params: { login: item.username } }"
        >
          <v-flex class="py-2">
            <span class="font-weight-regular">{{ item.username }}</span>
            <br />
            <span class="text-caption font-weight-light">{{ item.email }}</span>
          </v-flex>
        </router-link>
      </template>
      <template v-slot:[`item.accountExpiry`]="{ item }">
        <template v-if="isExpired(item.accountExpiry)">
          <v-icon
            v-if="isExpired(item.accountExpiry)"
            color="error"
            class="mr-2"
          >
            mdi-alert-outline
          </v-icon>
          <span class="error--text font-weight-medium">{{
            formatDisplayDate(item.accountExpiry)
          }}</span>
        </template>
        <template v-else>
          {{ formatDisplayDate(item.accountExpiry) }}
        </template>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <account-status-chip :status="item.status"></account-status-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template v-if="hasAccessToAccount(item, user)">
          <v-tooltip
            bottom
            v-if="
              item.status === 'ACTIVE' ||
              item.status === 'RECOVERY' ||
              item.status === 'PASSWORD_EXPIRED'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="secondary"
                @click.stop="openSendPasswordResetLinkDialog(item.username)"
              >
                <v-icon>mdi-lock-reset</v-icon>
              </v-btn>
            </template>
            <span>Send password reset link</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.status !== 'DEPROVISIONED'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="secondary"
                @click.stop="openResetFactorsDialog(item.username)"
              >
                <v-icon>mdi-lock-remove</v-icon>
              </v-btn>
            </template>
            <span>Reset multifactor</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.status === 'PROVISIONED'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="secondary"
                @click.stop="openSendActivationLinkDialog(item.username)"
              >
                <v-icon>mdi-account-arrow-right</v-icon>
              </v-btn>
            </template>
            <span>Send activation link</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="
              canDeactivateAccount(item, user) &&
              item.status !== 'DEPROVISIONED'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="secondary"
                @click.stop="openDeactivateAccountDialog(item.username)"
              >
                <v-icon>mdi-account-off</v-icon>
              </v-btn>
            </template>
            <span>Deactivate</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="
              canRequest(item, user) &&
              isExpiring(item.accountExpiry) &&
              item.status !== 'DEPROVISIONED'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="secondary"
                @click="requestForAccountExpiryExtension(item.username)"
              >
                <v-icon>mdi-timer-plus</v-icon>
              </v-btn>
            </template>
            <span>Request for account expiry extension</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="
              canRequest(item, user) &&
              ((item.status === 'SUSPENDED' &&
                !isExpiring(item.accountExpiry)) ||
                item.status === 'DEPROVISIONED')
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="secondary"
                @click="requestForAccountReactivation(item.username)"
              >
                <v-icon>mdi-account-reactivate</v-icon>
              </v-btn>
            </template>
            <span>Request for account reactivation</span>
          </v-tooltip>
        </template>
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
import { mapState } from 'pinia'
import { mapActions } from 'pinia'
import { useAccountStore } from '@/store/account'
import { useUserStore } from '@/store/user'
import FormattingMixin from '@/mixins/formatting.mixin'
import AccountMixin from '@/mixins/account.mixin'
import ResetFactors from '@/components/Dialogs/ResetFactors.vue'
import SendPasswordResetLink from '@/components/Dialogs/SendPasswordResetLink.vue'
import SendActivationLink from '@/components/Dialogs/SendActivationLink.vue'
import DeactivateAccount from '@/components/Dialogs/DeactivateAccount.vue'
import AccountStatusChip from '@/components/AccountStatusChip.vue'

export default {
  props: {
    resourceType: String,
    hideDefaultFooter: {
      type: Boolean,
      default: false
    },
    isWidget: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    tableTitle: {
      type: String,
      default: ''
    }
  },
  mixins: [AccountMixin, FormattingMixin],
  name: 'AccountTable',
  components: {
    ResetFactors,
    SendPasswordResetLink,
    SendActivationLink,
    DeactivateAccount,
    AccountStatusChip
  },
  data() {
    return {
      search: '',
      footerProps: { 'items-per-page-options': [5, 10, 25, 50, 100] },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false
      },
      isLoading: true,
      resetFactorsDialog: false,
      sendPasswordResetLinkDialog: false,
      sendActivationLinkDialog: false,
      deactivateAccountDialog: false,
      usernameForDialog: null
    }
  },
  computed: {
    currentPath() {
      return this.$router.history.current.fullPath
    },
    filteredAccounts() {
      var searchedFilteredResults = this.accounts.filter((account) => {
        //Allow searching for accounts by first name, last name, username, email, and name (last name, first name)
        return (
          account.username.toLowerCase().indexOf(this.search.toLowerCase().trim()) !== -1 
          || account.email.toLowerCase().indexOf(this.search.toLowerCase().trim()) !== -1 //company email, like @company.com
          || account.firstName.toLowerCase().indexOf(this.search.toLowerCase().trim()) !== -1
          || account.lastName.toLowerCase().indexOf(this.search.toLowerCase().trim()) !== -1
          || account.name.toLowerCase().indexOf(this.search.toLowerCase().trim()) !== -1
        )
      })
      return searchedFilteredResults // return this.accounts to just return all non-searched accounts. 
    },
    headers() {
      return this.isWidget ? this.accountHeadersWidget : this.accountHeaders
    },
    ...mapState(useAccountStore, [
      'accountSnackbar',
      'accounts',
      'accountHeaders',
      'accountHeadersWidget',
      'totalAccounts'
    ]),
    ...mapState(useUserStore, ['user'])
  },
  methods: {
    openResetFactorsDialog(username) {
      this.resetFactorsDialog = true
      this.usernameForDialog = username
    },
    openSendPasswordResetLinkDialog(username) {
      this.sendPasswordResetLinkDialog = true
      this.usernameForDialog = username
    },
    openSendActivationLinkDialog(username) {
      this.sendActivationLinkDialog = true
      this.usernameForDialog = username
    },
    openDeactivateAccountDialog(username) {
      this.deactivateAccountDialog = true
      this.usernameForDialog = username
    },
    requestForAccountExpiryExtension(guestAccountUsername) {
      this.$router.push({
        name: 'create-request',
        params: { guestAccountUsername, type: 'ACCOUNT_EXPIRY_EXTENSION' }
      })
    },
    requestForAccountReactivation(guestAccountUsername) {
      this.$router.push({
        name: 'create-request',
        params: { guestAccountUsername, type: 'ACCOUNT_REACTIVATION' }
      })
    },
    async refetchAccounts() {
      this.isLoading = true
      await this.fetchAccountsAsync().then((res) => {
        this.isLoading = false
      })
    },
    ...mapActions(useAccountStore, ['fetchAccountsAsync'])
  },
  watch: {
    async resetFactors(val) {
      if (!val) {
        await this.refetchAccounts()
      }
    },
    async sendPasswordResetLinkDialog(val) {
      if (!val) {
        await this.refetchAccounts()
      }
    },
    async sendActivationLinkDialog(val) {
      if (!val) {
        await this.refetchAccounts()
      }
    },
    async deactivateAccountDialog(val) {
      if (!val) {
        await this.refetchAccounts()
      }
    },
    async user(val) {
      if (val) {
        this.isLoading = true
        await this.fetchAccountsAsync().then((res) => {
          this.isLoading = false
        })
      }
    }
  },
  async mounted() {
    if (this.user) {
      this.isLoading = true
      await this.fetchAccountsAsync().then((res) => {
        this.isLoading = false
      })
    }
    if (this.itemsPerPage != this.options.itemsPerPage) {
      this.options.itemsPerPage = this.itemsPerPage
    }
  }
}
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: thin solid rgba(255, 234, 226, 0.75);
}

.theme--light.v-data-table {
  color: #192b40;
  letter-spacing: 1px;
  word-break: break-all;
}

.theme--light.v-data-table .text-caption {
  letter-spacing: 1px !important;
}

a {
  text-decoration: none;
}
</style>
