import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,[_c(VToolbar,{attrs:{"flat":"","color":"transparent"}},[_c(VToolbarTitle,{staticClass:"secondary--text overline",domProps:{"textContent":_vm._s(_vm.tableTitle)}}),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search Accounts","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-0",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.filteredAccounts,"hide-default-footer":_vm.hideDefaultFooter,"footer-props":_vm.footerProps,"loading":_vm.isLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VSnackbar,{attrs:{"color":_vm.accountSnackbar.color,"vertical":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.accountSnackbar.value = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.accountSnackbar.value),callback:function ($$v) {_vm.$set(_vm.accountSnackbar, "value", $$v)},expression:"accountSnackbar.value"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.accountSnackbar.text)}})]),_c('reset-factors',{attrs:{"resetFactorsDialog":_vm.resetFactorsDialog,"username":_vm.usernameForDialog},on:{"update:resetFactorsDialog":function($event){_vm.resetFactorsDialog=$event},"update:reset-factors-dialog":function($event){_vm.resetFactorsDialog=$event}}}),_c('send-password-reset-link',{attrs:{"sendPasswordResetLinkDialog":_vm.sendPasswordResetLinkDialog,"username":_vm.usernameForDialog},on:{"update:sendPasswordResetLinkDialog":function($event){_vm.sendPasswordResetLinkDialog=$event},"update:send-password-reset-link-dialog":function($event){_vm.sendPasswordResetLinkDialog=$event}}}),_c('send-activation-link',{attrs:{"sendActivationLinkDialog":_vm.sendActivationLinkDialog,"username":_vm.usernameForDialog},on:{"update:sendActivationLinkDialog":function($event){_vm.sendActivationLinkDialog=$event},"update:send-activation-link-dialog":function($event){_vm.sendActivationLinkDialog=$event}}}),_c('deactivate-account',{attrs:{"deactivateAccountDialog":_vm.deactivateAccountDialog,"username":_vm.usernameForDialog},on:{"update:deactivateAccountDialog":function($event){_vm.deactivateAccountDialog=$event},"update:deactivate-account-dialog":function($event){_vm.deactivateAccountDialog=$event}}})]},proxy:true},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'show-account', params: { login: item.username } }}},[_c(VFlex,{staticClass:"py-2"},[_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.username))]),_c('br'),_c('span',{staticClass:"text-caption font-weight-light"},[_vm._v(_vm._s(item.email))])])],1)]}},{key:"item.accountExpiry",fn:function(ref){
var item = ref.item;
return [(_vm.isExpired(item.accountExpiry))?[(_vm.isExpired(item.accountExpiry))?_c(VIcon,{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v(" mdi-alert-outline ")]):_vm._e(),_c('span',{staticClass:"error--text font-weight-medium"},[_vm._v(_vm._s(_vm.formatDisplayDate(item.accountExpiry)))])]:[_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.accountExpiry))+" ")]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('account-status-chip',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasAccessToAccount(item, _vm.user))?[(
            item.status === 'ACTIVE' ||
            item.status === 'RECOVERY' ||
            item.status === 'PASSWORD_EXPIRED'
          )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.openSendPasswordResetLinkDialog(item.username)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-lock-reset")])],1)]}}],null,true)},[_c('span',[_vm._v("Send password reset link")])]):_vm._e(),(item.status !== 'DEPROVISIONED')?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.openResetFactorsDialog(item.username)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-lock-remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Reset multifactor")])]):_vm._e(),(item.status === 'PROVISIONED')?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.openSendActivationLinkDialog(item.username)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-account-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Send activation link")])]):_vm._e(),(
            _vm.canDeactivateAccount(item, _vm.user) &&
            item.status !== 'DEPROVISIONED'
          )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.openDeactivateAccountDialog(item.username)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-account-off")])],1)]}}],null,true)},[_c('span',[_vm._v("Deactivate")])]):_vm._e(),(
            _vm.canRequest(item, _vm.user) &&
            _vm.isExpiring(item.accountExpiry) &&
            item.status !== 'DEPROVISIONED'
          )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.requestForAccountExpiryExtension(item.username)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-timer-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Request for account expiry extension")])]):_vm._e(),(
            _vm.canRequest(item, _vm.user) &&
            ((item.status === 'SUSPENDED' &&
              !_vm.isExpiring(item.accountExpiry)) ||
              item.status === 'DEPROVISIONED')
          )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.requestForAccountReactivation(item.username)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-account-reactivate")])],1)]}}],null,true)},[_c('span',[_vm._v("Request for account reactivation")])]):_vm._e()]:_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }