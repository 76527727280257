export default {
  data() {
    return {
      oktaUserStatuses: [
        {
          value: 'PROVISIONED',
          text: 'Provisioned',
          color: 'warning'
        },
        {
          value: 'ACTIVE',
          text: 'Active',
          color: 'success'
        },
        {
          value: 'PASSWORD_EXPIRED',
          text: 'Password Expired',
          color: 'error'
        },
        {
          value: 'DEPROVISIONED',
          text: 'Deprovisioned',
          color: 'error'
        },
        {
          value: 'SUSPENDED',
          text: 'Suspended',
          color: 'error'
        },
        {
          value: 'RECOVERY',
          text: 'Recovery',
          color: 'warning'
        }
      ]
    }
  },
  methods: {
    isExpired(date) {
      return this.$moment().isSameOrAfter(this.$moment(date).format())
    },
    isExpiring(date) {
      return this.$moment().isSameOrAfter(this.$moment(date).subtract(14, 'days'))
    },
    isSponsor(sponsorEmployeeNumber, userEmployeeNumber) {
      return (_.trim(sponsorEmployeeNumber) === _.trim(userEmployeeNumber))
    },
    canDeactivateAccount(guestAccount, user) {
      // if user is sponsor or manager of sponsor organization
      let sponsorOrgCode = _.trim(guestAccount.sponsorOrgCode)

      for (let i = 0; i < user.ownedOrganizations.length; i++) { //check of the user is an owner on the org
        if (_.trim(user.ownedOrganizations[i].code) === sponsorOrgCode)
          return true
      }

      if (_.trim(guestAccount.sponsorEmployeeNumber) === _.trim(user.employeeNumber))
        return true

      return false
    },
    canSubmitRequest(sponsorOrgCode, user) {
      for (let i = 0; i < user.ownedOrganizations.length; i++) { //check of the user is an owner on the org
        if (_.trim(user.ownedOrganizations[i].code) === sponsorOrgCode)
          return false
      }
      
      return true
    },
    canRequest(guestAccount, user) {
      // true if the user is the account sponsor, or if the user is the owner of the account sponsored org

      let sponsorOrgCode = _.trim(guestAccount.sponsorOrgCode)

      // false if the user is the sponsor org manager
      for (let i = 0; i < user.ownedOrganizations.length; i++) {
        if (_.trim(user.ownedOrganizations[i].code) === sponsorOrgCode) {
          return false
        }
      }

      // true if the user is the account sponsor
      if (_.trim(guestAccount.sponsorEmployeeNumber) === _.trim(user.employeeNumber))
        return true

      // true if the user is a member of the sponsoring org
      for (let i = 0; i < user.organizations.length; i++) { //check if the user is a member of the org
        if (_.trim(user.organizations[i].code) === sponsorOrgCode)
          return true
      }
      return false
    },
    canModifyAccountDetails(guestAccount, user) {
      // true if the user is the account sponsor, or if the user is the owner of the account sponsored org
      if (_.trim(guestAccount.sponsorEmployeeNumber) === _.trim(user.employeeNumber))
        return true

      let sponsorOrgCode = _.trim(guestAccount.sponsorOrgCode)
      for (let i = 0; i < user.ownedOrganizations.length; i++) {
        if (_.trim(user.ownedOrganizations[i].code) === sponsorOrgCode) {
          return true
        }
      }

      return false
    },
    canUnsubscribeAccountFromService(guestAccount, user) {
      // true if the user is the account sponsor, or if the user is the owner of the account sponsored org
      if (_.trim(guestAccount.sponsorEmployeeNumber) === _.trim(user.employeeNumber))
        return true

      let sponsorOrgCode = _.trim(guestAccount.sponsorOrgCode)
      for (let i = 0; i < user.ownedOrganizations.length; i++) {
        if (_.trim(user.ownedOrganizations[i].code) === sponsorOrgCode) {
          return true
        }
      }

      return false
    },
    hasAccessToAccount(guestAccount, user) {
      // Has read access to the account. true if :
      // a) the user is the account sponsor, or 
      // b) if the account sponsored org is one of the user's organzations, or 
      // c) if the user is the owner of the account sponsored org
      if (_.trim(guestAccount.sponsorEmployeeNumber) === _.trim(user.employeeNumber))
        return true

      let sponsorOrgCode = _.trim(guestAccount.sponsorOrgCode)

      for (let i = 0; i < user.organizations.length; i++) { //check if the user is a member of the org
        if (_.trim(user.organizations[i].code) === sponsorOrgCode)
          return true
      }

      for (let i = 0; i < user.ownedOrganizations.length; i++) { //check of the user is an owner on the org
        if (_.trim(user.ownedOrganizations[i].code) === sponsorOrgCode)
          return true
      }

      return false
    }
  }
}